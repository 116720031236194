<template>
    <el-scrollbar class="scrollbar-list">
        <classicCase></classicCase>
        <div class="official-footer">
            <OfficialFooter></OfficialFooter>
        </div>
    </el-scrollbar>
</template>

<script>
    import OfficialFooter from '@/components/OfficialFooter'
    import classicCase from '@/components/classiccase/classicCase'

    export default {
        name: "Classic_case",
        components: {
            OfficialFooter,
            classicCase
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
    .scrollbar-list {
        height: 100%;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>